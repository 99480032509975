import React from "react";
import Navbar from "../../components/Navbar";

function ContactUs() {
  return (
    <div>
      <Navbar />
      ContactUs
    </div>
  );
}

export default ContactUs;
