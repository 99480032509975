import React from "react";

import "./Footer.css";
function Footer() {
  return (
    <div className="farm-2-clinic">
      <div className="farm-2-clinic-text">© 2017 FARM2CLINIC</div>
    </div>
  );
}

export default Footer;
