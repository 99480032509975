import React from "react";
import Navbar from "../../components/Navbar";

function About() {
  return (
    <div>
      <Navbar />
      About
    </div>
  );
}

export default About;
